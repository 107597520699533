import classes from './Profile.module.css'
import Post from '../Post/Post.jsx';

const Profile = () =>{

    return(
    <div className={classes.Profile}>
        <div className={classes.bgimg}></div>
        
        
        <Post userName="User1" mass="Hello 1 mess" like="2"/>
        <Post userName="User2" mass="Hello 2 mess" like="3"/>
        <Post userName="User3" mass="Hello 3 mess" like="6"/>
        <Post userName="User4" mass="Hello 4 mess" like="1"/>
    </div>
    );
}

export default Profile