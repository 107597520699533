import Header from './../src/component/Header/Header';
import Navbar from './../src/component/Navbar/Navbar';
import Profile from './component/Profile/Profile';
import Futer from './../src/component/Futer/Futer';
import Portfolio from './component/Portfolio/Portfolio'
import './App.css'
import AboutMe from './component/AboutMe/AboutMe'
import { Route, BrowserRouter, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <Header />
        <Navbar />
        <Routes>
          <Route path='/' element={<Profile />} />
          <Route path='/AboutMe' element={<AboutMe />} />
          <Route path='/Portfolio' element={<Portfolio />} />
        </Routes>
        <Futer />
      </div>
    </BrowserRouter>
  );
}




export default App;
