import React from "react"
import classes from './AboutMe.module.css'

const AboutMe = () =>{
    return(
        <div className={classes.AboutMe}>
            <span>About Me</span>
        </div>
    )

}

export default AboutMe;