import classes from './Portfolio.module.css';

const Profile = () =>{
    return(
    <div className={classes.item}>
        portfolio
    </div>
    );
}

export default Profile