import classes from "./Post.module.css"

const Post = (props) => {
    return (
        <div className={classes.post}>
            <img src="https://i.work.ua/article/579b.jpg?v=1713862862" />
            <h3>{props.userName}</h3>
            <h4>{props.mass}</h4>
            <a href="#" >Like {props.like}</a>
        </div>
    )
}

export default Post