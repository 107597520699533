import classes from './Header.module.css'


const Header = () => {
    return (
      <div className={classes.Header}>
        <div>HEAD Header</div>
      </div>
    );
  }
  
export default Header