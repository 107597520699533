import { NavLink } from 'react-router-dom';
import classes from './Navbar.module.css'

const Navbar = ()=>{
    const a = navData => navData.isActive ? classes.active : classes.button 
    
    return(
     <div className={classes.Navbar}>
        <NavLink to="/" className={a}>Main</NavLink>
        <NavLink to="/AboutMe" className={a}>About me</NavLink>
        <NavLink to="/Portfolio" className={a}>Portfolio</NavLink>
     </div>
    )
};

export default Navbar