import classes from './Futer.module.css'

const Futer = () =>{
    return(
        <div className={classes.Futer}>
            FUTER
        </div>
    );
};

export default Futer